// THEM OVERWRITE START




//#genki{

/*.button.button-default{
    background-color: map-get($colors, primary);
}*/

.alert-button.alert-button-default{
    color: map-get($colors, dark);
}

.select-alert [aria-checked=true] .alert-radio-label{
    color: map-get($colors, success);
}

/*
.alert-input:focus{
    border-bottom: 2px solid map-get($colors, primary) !important;
}*/

@each $key, $value in $colors{

    .button[color="#{$key}"]{
        background-color: map-get($colors, #{$key});
    }

    .button.button-outline[color="#{$key}"], .button.button-outline[color="#{$key}"] .icon{
        border-color: map-get($colors, #{$key});
        color: map-get($colors, #{$key});
    }

    .button.button-clear[color="#{$key}"], .button.button-clear[color="#{$key}"] .icon{   
        background-color: transparent;
        color: map-get($colors, #{$key});
    }

    .label[color="#{$key}"]{   
        color: map-get($colors, #{$key});
    }

    .bar-button-clear[color="#{$key}"], .bar-button-clear[color="#{$key}"] .icon{   
        color: map-get($colors, #{$key});
    }

}

// .button.button-clear{
//     color: map-get($colors, primary);
//     background-color: transparent;
// }

.button.button-outline{

    background-color: transparent;
    border-color: map-get($colors, primary);
    color: map-get($colors, primary);
}

.toolbar-title{
    color: map-get($colors, contrast);
}


//}



.content{
    background-color: map-get($colors, background);
}

.toolbar-background{
    background-color: map-get($colors, toolbar-background);
}



// THEME OVERWRITE END