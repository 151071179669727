$font-version: random(10000);

@font-face {
    font-family: 'icomoon';
    src: 
        url('/assets/fonts/icomoon.woff2?' + $font-version) format('woff2'),
        url('/assets/fonts/icomoon.ttf?' + $font-version) format('truetype'),
        url('/assets/fonts/icomoon.woff?' + $font-version) format('woff'),
        url('/assets/fonts/icomoon.svg?'  + $font-version + '#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
 
@mixin makeIcon($arg, $val) {
    .ion-ios-#{$arg}:before ,
    .ion-md-#{$arg}:before  {
        font-family: "icomoon" !important;
        content: $val;
    }
}
